export default [
  {
    key: 'stockBinId',
    label: 'field.bin',
    type: 'nAsynSingleSelection',
    repository: 'stockBin',
    selectionKey: 'id',
    selectionLabel: 'code',
    clearable: true,
  },
  {
    key: 'itemId',
    label: 'field.item',
    type: 'nAsynSingleSelection',
    repository: 'item',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
  },
  {
    key: 'type',
    label: 'field.type',
    type: 'radio',
    options: [
      { text: 'general.all', value: '' },
      { text: 'general.in', value: 'in' },
      { text: 'general.out', value: 'out' },
    ],
    lg: 6,
  },
  {
    key: 'fromDate',
    label: 'field.fromDate',
    type: 'date',
    searchField: 'createdAt',
    operator: 'd>=',
  },
  {
    key: 'toDate',
    label: 'field.toDate',
    type: 'date',
    searchField: 'createdAt',
    operator: 'd<=',
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
    ],
    md: 12,
    lg: 6,
    skip: true,
  },
  {
    key: 'reportType',
    label: 'field.type',
    type: 'radio',
    options: [
      { text: 'general.monthlyReport', value: 3 },
      { text: 'general.finanaceMonthlyReport', value: 4 },
    ],
    md: 12,
    lg: 12,
    skip: true,
  },
];
