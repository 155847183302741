<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      @excel="exportExcel"
      @pdf="exportModal"
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :default-visible="true"
      show-export-excel
      show-export-pdf
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :type="permissionType"
          :updatable="false"
        >
          <template #cell(type)="data">
            <span :class="`text-${data.item.type}`">
              {{ $t("general." + data.item.type) }}
            </span>
          </template>
          <template #cell(quantity)="data">
            <span :class="`text-${data.item.type}`">
              {{ data.item.quantity }}
            </span>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
    <export-modal ref="exportModal" @export="confirmExport"></export-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import ExportModal from "./ExportModal";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const StockReportRepository = Repository.get("stockReport");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BLink,
    vSelect,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    ExportModal,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.fromDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        sortby: this.$route.query.sortby || "id",
        sort: this.$route.query.sort || "asc",
        type: this.$route.query.type || "",
        reportType: Number(this.$route.query.reportType) || 3,
        search: this.$route.query.search || null,
        stockLocationId: Number(this.$route.query.stockLocationId) || null,
        stockBinId: Number(this.$route.query.stockBinId) || null,
        itemId: Number(this.$route.query.itemId) || null,
        fromDate: this.$route.query.fromDate || moment().format("YYYY-MM-DD"),
        toDate: this.$route.query.toDate || moment().format("YYYY-MM-DD"),
        period: this.$route.query.period || "today",
      },
    };
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";

    this.getData();
  },
  methods: {
    exportModal() {
      this.$refs.exportModal.show();
    },
    confirmExport(data) {
      this.exportPdf(data);
    },
    exportPdf(data) {
      const params = {
        ...this.params,
        fromDate: `${this.params.fromDate} 00:00:00`,
        toDate: `${this.params.toDate} 23:59:59`,
        searchFields: this.searchFields,
        ...data,
      };
      this.loading = true;
      StockReportRepository.exportPdf(params)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Export Report ${moment().format(
            "DD-MMM-YYYY"
          )}.pdf`;
          this.a.click();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportExcel() {
      this.loading = true;
      const params = {
        ...this.params,
        fromDate: `${this.params.fromDate} 00:00:00`,
        toDate: `${this.params.toDate} 23:59:59`,
        searchFields: this.searchFields,
      }
      StockReportRepository.exportExcel(params)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Stock Report ${moment().format(
            "DD-MMM-YYYY"
          )}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = searchText;
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.params.search = "";
      this.$refs.search.reset();
      this.params.reportType = 3;
      this.params.fromDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;

      StockReportRepository.index({
        ...this.params,
        fromDate: `${this.params.fromDate} 00:00:00`,
        toDate: `${this.params.toDate} 23:59:59`,
        searchFields: this.searchFields,
      })
        .then((response) => {
          this.fields = [...TableFields];
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const searchFields = SearchInputs;
    const permissionType = "stock-report";
    let fields = [...TableFields];

    return {
      fields,
      searchFields,
      permissionType,
    };
  },
};
</script>